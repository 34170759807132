




































































































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import GenericModalDelete from '@/partials/modals/generic/delete.vue'

@Component({
  components: {
    GenericModalDelete
  },
  filters: {
    translateStatus: function (value: 'pass' | 'kill' | 'pend' | 'fail') {
      const dict = {
        pass: 'Aprovado',
        kill: 'Eliminado',
        pend: 'Pendente',
        fail: 'Reprovado'
      }

      return dict[value]
    }
  }
})
export default class ExamsScheduledMonitor extends Vue {
  responseError = ''
  filteredData: Array<any> = []
  users: Array<any> = []
  selectedUser = {
    id: '',
    name: ''
  }

  deleteModalIsOpened = false
  restoreModalIsOpened = false

  private intervalId = 0
  created () {
    this.fetchUsers()
    this.intervalId = setInterval(() => {
      this.fetchUsers()
    }, 15000)
  }

  beforeDestroy () {
    clearInterval(this.intervalId)
  }

  fetchUsers () {
    axios.get(`/exam/${this.$route.params.examId}/schedule/${this.$route.params.scheduleId}/monitor`)
      .then(response => {
        this.users = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
      })
      .catch(err => {
        this.responseError = err.response.data?.error
      })
  }

  get deleteUrl () {
    return `/exam/${this.$route.params.examId}/schedule/${this.$route.params.scheduleId}/user/${this.selectedUser.id}/eliminate`
  }

  get restoreUrl () {
    return `/exam/${this.$route.params.examId}/schedule/${this.$route.params.scheduleId}/user/${this.selectedUser.id}/restore`
  }

  tagKind (status: 'pass' | 'kill' | 'pend' | 'fail') {
    const dict = {
      pass: 'green',
      kill: 'red',
      fail: 'cool-gray',
      pend: 'warm-gray'
    }

    return dict[status]
  }
}
